import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import VueDeviceDetector from 'vue-device-detector';
Vue.use(VueDeviceDetector);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
