<template>
  <div id="app">
  </div>
</template>

<script>
export default {
  data(){
    return{

    }
  },
  mounted(){
    console.log("进来了");
    console.log(this.$device.mobile,"我是手机")
    console.log(this.$device.ipad,"我是ipad");
    console.log(this.$device.windows,"我是电脑");
    if (this.$device.mobile || this.$device.ipad)
    {
      console.log("手机登录")
      window.location.href = "http://yhsapp.yhsprojects.com.au";
    }
    else
    {
      console.log("电脑登录")
      window.location.href = "http://yhspc.yhsprojects.com.au";
    }
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
